import parse from 'date-fns/parse';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import timeZoneData from './timezones.json';

export const defaultTimeSlot = Array.from({ length: 7 }, (_, day) => ({
  day,
  slots: [
    {
      openAllDay: false,
      to: '',
      from: '',
      valid: false,
    },
  ],
}));

export const generateTimeSlots = (step = 15) => {
  /*
    Generates a list of time strings from 12:00 AM to next 24 hours. Each new string
     will be generated by adding `step` minutes to the previous one.
    The list is generated by starting with a random day and adding step minutes till end of the same day.
  */
  const date = new Date(1970, 1, 1);
  const slots = [];
  while (date.getDate() === 1) {
    slots.push(
      date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
    );
    date.setMinutes(date.getMinutes() + step);
  }
  return slots;
};

export const getTime = (hour, minute) => {
  const merdian = hour > 11 ? 'PM' : 'AM';
  const modHour = hour > 12 ? hour % 12 : hour || 12;
  const parsedHour = modHour < 10 ? `0${modHour}` : modHour;
  const parsedMinute = minute < 10 ? `0${minute}` : minute;
  return `${parsedHour}:${parsedMinute} ${merdian}`;
};

export const timeSlotParse = timeSlots => {
  const groupedByDay = {};

  timeSlots.forEach(slot => {
    const {
      day_of_week: day,
      open_hour: openHour,
      open_minutes: openMinutes,
      close_hour: closeHour,
      close_minutes: closeMinutes,
      closed_all_day: closedAllDay,
      open_all_day: openAllDay,
    } = slot;
    const from = closedAllDay ? '' : getTime(openHour, openMinutes);
    const to = closedAllDay ? '' : getTime(closeHour, closeMinutes);

    if (!groupedByDay[day]) {
      groupedByDay[day] = [];
    }

    groupedByDay[day].push({
      to,
      from,
      valid: !closedAllDay,
      openAllDay,
    });
  });

  return Object.keys(groupedByDay).map(day => ({
    day,
    slots: groupedByDay[day],
  }));
};

export const timeSlotTransform = (timeSlots, inbox_id) => {
  return timeSlots.map(daySlot => {
    return daySlot.slots
      .filter(slot => {
        if (daySlot.slots.length > 1 && !slot.from && !slot.to) {
          return false;
        }
        return true;
      })
      .map(slot => {
        const closed = slot.openAllDay ? false : !(slot.to && slot.from);
        const openAllDay = slot.openAllDay;
        let fromDate = '';
        let toDate = '';
        let openHour = '';
        let openMinutes = '';
        let closeHour = '';
        let closeMinutes = '';

        if (!closed) {
          fromDate = parse(slot.from, 'hh:mm a', new Date());
          toDate = parse(slot.to, 'hh:mm a', new Date());
          openHour = getHours(fromDate);
          openMinutes = getMinutes(fromDate);
          closeHour = getHours(toDate);
          closeMinutes = getMinutes(toDate);
        }

        return {
          day_of_week: daySlot.day,
          closed_all_day: closed,
          open_hour: openHour,
          open_minutes: openMinutes,
          close_hour: closeHour,
          close_minutes: closeMinutes,
          open_all_day: openAllDay,
          workable_type: 'Inbox',
          workable_id: inbox_id
        };
      });
  }).flat();
};

export const timeZoneOptions = () => {
  return Object.keys(timeZoneData).map(key => ({
    label: key,
    value: timeZoneData[key],
  }));
};

export const hasTimeSlotOverlap = (slots, targetSlot) => {
  if (!targetSlot.from || !targetSlot.to) {
    return false;
  }

  const targetFrom = parse(targetSlot.from, 'hh:mm a', new Date());
  const targetTo = parse(targetSlot.to, 'hh:mm a', new Date());

  return slots.some(slot => {
    if (slot.from && slot.to) {
      const from = parse(slot.from, 'hh:mm a', new Date());
      const to = parse(slot.to, 'hh:mm a', new Date());

      return (targetFrom < to && targetFrom >= from) || (from < targetTo && from >= targetFrom);
    }
    return false;
  });
};

export const dayNames = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
];